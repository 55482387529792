
/* You can add global styles to this file, and also import other style files */
// fonts scss file
// @import "//fonts";
@import 'material-icons/iconfont/material-icons.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';
@import "variables";
@import "mixins";
@import "buttons";
@import "fonts";
@import "common";
@import "typography";
@import "sections";
@import "mystyle";
@import "base";
@import "./material-styles/ag-grid";

@import "./components/pro-card";
@import "./material-styles/index";
@import "./material-styles/button";
@import "./material-styles/menu";
@import "./material-styles/mat-form";
@import "./material-styles/checkbox";
@import "./material-styles/datepicker";
@import "./material-styles/button-toggle";
@import "./material-styles/paginator";
@import "./material-styles/tooltip";
@import "./material-styles/tab";
@import "./material-styles/radio";
@import "./material-styles/dialog";
@import "./material-styles/drawer";
@import "./material-styles/snackbar";
@import "./material-styles/badge";

