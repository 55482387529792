.mat-button-toggle{
    border-radius: 8px;
}

.mat-button-toggle-button{

}

.mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard{
    color: $blue-900;
    background-color: $gray-200;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle{
    border-left: none;
}

.mat-button-toggle-group-appearance-standard{
    border: none;
    border-radius: none;
    display: flex;
    gap: 8px;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard.mat-button-toggle-checked{
    background-color: $blue-600;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard{
    color: #FFFFFF
}

.mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
    padding: 0px 16px;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
    line-height: 0;
}
