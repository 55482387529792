.mat-datepicker-toggle {
  .icon-btn {
    color: $blue-700 !important;
    font-size: 20px;
    height: 20px;
    width: 20px;
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple{
    display:none;
  }

  .mat-mdc-icon-button .mat-mdc-button-ripple{
    display: none;
  }
}

.mat-datepicker-content {
  border-radius: 8px !important;
  box-shadow: 0px 3px 14px 2px #0000001F, 0px 8px 10px 1px #00000024, 0px 5px 5px -3px #00000033;

  .mat-calendar-controls {
    @include font(16px, 24px, 400);
    color: $gray-800;
  }
}

