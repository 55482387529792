$primary:#0E409C;
$primaryDark:#07317E;
$primaryDarker:#02225E;
$primaryLight: #02225E;
$primaryLighter: #8D9DB9;

$fontMain: Inter, sans-serif;
$fontSecond: 'Alkatra', cursive;

$headerHeight: 80px;
$alert: #E53E3E;

$gray-100: #EDF2F7;
$gray-200: #E2E8F0;
$gray-300: #CBD5E0;
$gray-400: #A0AEC0;
$gray-500: #718096;
$gray-600: #4A5568;
$gray-700: #2D3748;
$gray-800: #1A202C;

$blue-50: #FBFCFF;
$blue-100: #EBF4FC;
$blue-200: #DEE6FB;
$blue-300: #C5D2F7;
$blue-400: #A4B9F3;
$blue-500: #7C9AED;
$blue-600: #4C75E7;
$blue-700: #1739D3;
$blue-800: #102B6F;
$blue-900: #091740;

$red-100: #FED7D7;
$red-200: #FEB2B2;
$red-500: #E53E3E;
$red-600: #C53030;
$red-900: #63171B;

$yellow-200:#FAF089;
$yellow-400:#ECC94B;
$yellow-500:#D69E2E;
$yellow-900: #5F370E;


$green-100: #C6F6D5;
$green-200: #9AE6B4;
$green-500: #38A169;
$green-600: #25855A;
$green-900: #1C4532;

$cyan-300: #C9EAF8;
$cyan-700: #27AAE1;
$cyan-900: #1C4532;
