.custom-notification {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.notification-content h3 {
  margin: 0;
}


img {
  max-width: 100%;
}

// Humburger Menu Icons Style Start
* {
  margin: 0;
  padding: 0;
}
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.custom-scroll {
  @extend %scroll_style;
}
// AG GRID STYLE START HERE
// AgGrid scroll
.ag-body-vertical-scroll-viewport {
  @extend %scroll_style;
  width: 6px !important;
  max-width: 6px !important;
  min-width: 6px !important;
}
.ag-root-wrapper {
  border: 0;
  border-radius: 8px;
}




// AG GRID STYLE END HERE
/* Icon 1 */

.left-sidebar-toggle {
  width: 60px;
  height: 45px;
  position: relative;
  /* margin: 50px auto; */
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: absolute;
  transform: scale(0.4);
  /* border: 1px solid; */
  padding: 2px;
  display: inline-flex;
}
a {
  text-decoration: none;
}
hr {
  margin: 10px 0;
  color: inherit;
  border: 0;
  border-top: 1px solid #d9d9d9;
  opacity: 1;
}
.left-sidebar-toggle {
}

.left-sidebar-toggle span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: $primary;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.left-sidebar-toggle span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.left-sidebar-toggle span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

.left-sidebar-toggle span:nth-child(1),
.left-sidebar-toggle span:nth-child(2) {
  top: 0px;
}

.left-sidebar-toggle span:nth-child(3),
.left-sidebar-toggle span:nth-child(4) {
  top: 18px;
}

.left-sidebar-toggle span:nth-child(5),
.left-sidebar-toggle span:nth-child(6) {
  top: 36px;
}

.left-sidebar-toggle.open span:nth-child(1),
.left-sidebar-toggle.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.left-sidebar-toggle.open span:nth-child(2),
.left-sidebar-toggle.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.left-sidebar-toggle.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

.left-sidebar-toggle.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

.left-sidebar-toggle.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.left-sidebar-toggle.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.left-sidebar-toggle.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

.left-sidebar-toggle.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* Icon 3 */
p {
  margin: 0;
}
.animate-labels-form {
  .form-group:not(.not-animate) {
    position: relative;
    margin-bottom: 20px;

    input,
    select {
      font-size: 13px;
      padding: 4px 12px;
      display: block;
      width: 100%;
      height: 38px;
      border-radius: 0;
      background-color: transparent;
      border: none;
      border: 1px solid #c5c5c5;
    }

    input:focus,
    select:focus,
    textarea:focus {
      outline: none;
      border: 1px solid $primary;
    }

    label {
      color: #999;
      font-size: 12px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 12px;
      top: 10px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:focus label,
    textarea:focus ~ label,
    textarea:not(:placeholder-shown) ~ label {
      top: -8px;
      left: 6px;
      font-size: 11px;
      color: $primary;
      background: #fff;
      padding: 0 7px;
    }

    select:focus ~ label,
    select:not([value=""]):valid ~ label {
      top: -8px;
      left: 6px;
      font-size: 11px;
      color: $primary;
      background: #fff;
      padding: 0 7px;
    }

    /* active state */
    input:focus ~ .bar:before,
    input:focus ~ .bar:after,
    select:focus ~ .bar:before,
    select:focus ~ .bar:after {
      width: 50%;
    }

    textarea {
      min-height: 75px;
      max-height: 260px;
      overflow: hidden;
      overflow-x: hidden;
      resize: none;
      border-color: #c5c5c5;
      border-radius: 0;
      font-size: 13px;
      padding: 10px 12px;
    }
  }
}

// ngx fileuploader style start
.drag-dorp-label {
  i {
    font-size: 34px;
    color: $primary;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  span {
    font-size: 19px;
    font-weight: 600;
    color: $primary;
  }
}
.custom-dropzone {
  border: 1px dashed $gray-300 !important;
  min-height: 140px !important;
  padding: 24px 16px 24px 16px;

  display: flex;
  position: relative;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 8px;
  @extend %scroll_style;
  > ngx-dropzone-label {
    font-size: 14px;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
  }

  ngx-dropzone-image-preview {
    width: 100%;
    height: 56px !important;
    min-height: unset !important;
    display: flex !important;
    justify-content: flex-start !important;
    margin-top: 0 !important;
    margin-bottom: 0px !important;
    margin: 0 !important;
    padding: 16px !important;
    border-radius: 8px;
    border: 1px solid $blue-200;

    img {
      display: none;
    }
    &:before {
      display: none;
      line-height: 0;
      font-family: "Font Awesome 5 Pro";
      font-weight: regular;
      content: "\f15c";
      width: 29px;
      height: 30px;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      color: #0e409c;
      background-color: #ebebeb;
    }
    &.jpeg:before {
      content: "\f1c5";
      font-weight: bold;
    }
    &.png:before {
      content: "\f1c5";
      font-weight: 300;
    }
    &.pdf:before {
      content: "\f1c1";
    }
    &.msword:before {
      content: "\f1c2";
    }
    &.vnd\.openxmlformats-officedocument\.wordprocessingml\.document:before {
      content: "\f1c2";
      font-weight: 300;
    }
  }

  ngx-dropzone-image-preview img {
    width: 30px;
    height: 30px;
    opacity: 0 !important;
  }

  ngx-dropzone-image-preview ngx-dropzone-label {
    @include font(14px, 20px, 700);
    color: $blue-700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 80px);
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 8px;

    .mat-icon {
      color: $blue-700;
    }
  }

  ngx-dropzone-remove-badge {
    position: static !important;
    opacity: 1 !important;
    margin-left: auto;
    width: 24px !important;
    min-width: 24px;
    height: 24px !important;
    background: transparent !important;
    transform: scale(1.2);

    svg {
      height: 14px;
      width: 14px;
    }
    svg > line {
      stroke: $blue-700 !important;
    }
  }

  ngx-dropzone-image-preview:not(:last-child) {
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;
  }
  &.custom-single-dropzone {
    height: 55px;

    .drag-dorp-label {
      display: flex;
      align-items: center;
      i {
        font-size: 19px;
        margin: 0;
        margin-right: 10px;
      }
      span {
        font-size: 15px;
      }
    }
  }

  .drag-dorp-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .upload-inst{
      @include font(16px, 24px,400);
      color: $gray-800;

      strong{
        @include font(16px, 24px,600);
        color: $blue-700;
      }
    }

    .upload-size{
      @include font(14px, 20px,400);
      color: $gray-600;
    }
  }

  .icon-upload {
    background-color: $blue-200;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 8px;
    color: $blue-700;

    .mat-icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
  }
}
// ngx fileuploader style end
.error {
  width: 100%;
  margin-top: 0.25rem;
  @include font(12px, 16px, 600);
  color: $red-500
}
.hide {
  display: none;
}
.show {
  display: block;
}

input[readonly] {
  background-color: rgb(247, 247, 247) !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pcs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 10px);

  .pcs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pcs-navigation-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .back-btn {
        min-width: 36px;

        .mat-icon {
          font-size: 21px;
          height: 20px;
          width: 20px;
          margin-right: 0;
          color: $gray-600;
        }
      }
      .pcs-navigation-buttons {
        margin-left: 15px;
        display: flex;
        gap: 8px;
        .nav-button {
          border-radius: 50%;
          min-width: 40px;
        }
      }
    }

    .pcs-title {
      color: $blue-800;
      @include font(24px, 32px, 600);
      margin: 0;
    }
  }
  .pro-card {
    flex: 1 1 0px;
    width: 100%;
    margin-top: 24px;
    padding: 0;

    #pcs-Grid {
      height: 100%;
      min-height: 500px;
    }
  }
}
