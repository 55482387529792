.pro-card {
  padding: 16px;
  background-color: $blue-50;
  border-radius: 8px;
  border: 1px solid $blue-200;

  .pro-card-title {
    color: $blue-800;
    @include font(24px, 32px, 600);
    margin: 0;


  }
  h3.pro-card-title{
    @include font(14px, 20px, 600);
  }
}
