.btn-primary {
  background-color: $primary;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  border: 0;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $primaryDark !important;
  }
}
.btn-primary-gradient {
  background: #1e3c72;
  background: -webkit-linear-gradient(to left, #2a5298, #1e3c72);
  background: linear-gradient(to left, #2a5298, #1e3c72);
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  border: 0;
  color: #fff;
  justify-content: center;
  &:hover,
  &:active,
  &:focus,
  &.active {
    color: #fff;
    background: #1e3c72;
    background: -webkit-linear-gradient(to right, #2a5298, #1e3c72);
    background: linear-gradient(to right, #2a5298, #1e3c72);
  }
}
.btn-outline-primary {
  border-color: $primary;
  color: $primary;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:first-child:active {
    background-color: $primary;
    border-color: $primary;
  }
}
.btn {
  border-radius: 7px;
  font-weight: 500;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
  &:focus,
  &:focus-visible,
  &:active:focus-visible,
  &:first-child:active:focus-visible {
    box-shadow: none;
  }
}
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #0e409c;
  font-size: 14px;
  margin: 0px 1px;
  padding: 0;
  border: 1px solid;
  i {
    margin: 0;
  }
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}
.btn.btn-icon-fill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: $primary;
  color: #fff;
  font-size: 14px;
  margin: 0px 1px;
  border: 0;
  padding: 0;
  i {
    margin: 0;
  }
  &:hover {
    background-color: $primaryDark;
    color: #fff;
  }
}
.btn.btn-icon-fill-light {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgb(14, 64, 156, 0.7);
  color: #fff;
  font-size: 14px;
  margin: 0px 1px;
  border: 0;
  padding: 0;
  i {
    margin: 0;
  }
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.btn.btn-outline-primary, .btn.btn-outline-danger, .btn.btn-outline-success {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  margin: 0px 1px;
  i {
    margin: 0;
  }
}

.btn.btn-outline-primary {
  border-color: $blue-600;
  &:hover {
    background-color: $blue-600;
    color: #fff;
  }
}
.btn.btn-outline-danger {
  border-color: $red-600;

  &:hover {
    background-color: $red-600;
    color: #fff;
  }
}

.btn.w-32 {
  width: 32px;
  height: 32px;
  padding: 0;

}

.btn.btn-outline-success {
  border-color: $green-600;

  &:hover {
    background-color: $green-600;
    color: #fff;
  }
}

.btn.btn-primary-light {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: rgb(14, 64, 156, 0.7);
  color: #fff;
  font-size: 14px;
  margin: 0px 1px;
  border: 0;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  i {
    // margin-r: 0;
  }
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

// Add product button
button.btn.add-product-btn {
  padding: 0;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 21px;
  color: $primary;
  i {
    margin: 0;
  }
  &:hover{
    color: $primaryDark;
  }
}
.btn-primary.custom-btn {
  padding: 4px 11px;
  font-size: 13px;
  font-weight: normal;
  justify-content: center;
  min-width: 96px;
  background-color: rgb(14, 64, 156, 0.7);
  &:hover{
    background-color: $primary;
  }
}
// Checkbox buttons

.checkbox-radio {
  position: relative;
  display: flex;
  align-items: center;
  input {
    + label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 18px;
      width: 18px;
      display: block;
      &:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 6px;
        transform: rotate(-45deg);
        width: 10px;
      }
      + label {
        margin-left: 8px;
      }
    }
    &[type="checkbox"] {
      + label {
        border-radius: 0;
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      visibility: hidden;
      display: none;
      opacity: 0;
      &:checked + label {
        background-color: $primary;
        border-color: $primary;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.btn-outer-icon {
  padding: 5px 12px 5px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #1739D3;
  color: #1739D3;
  background-color: #fff;
  display: flex;
  align-items: center;

  .icon {
    font-size: 14px;
  }

  .btn-outer-text {
    @include font(14px, 20px, 600)
  }
}
