.mat-mdc-select-arrow-wrapper {
  opacity: 0;
  display: none !important;
}

.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0 12px;
  font-size: 20px;
  color: $blue-700;
  height: 20px;
  width: 20px;
}

.custom-field
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-radius: 0 4px 4px 0;
  border-top: 1px solid $blue-200;
  border-right: 1px solid $blue-200;
  border-bottom: 1px solid $blue-200;
}
.custom-field.radius-8
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-radius: 0 8px 8px 0;
}

.custom-field
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__leading {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid $blue-200;
  border-top: 1px solid $blue-200;
  border-bottom: 1px solid $blue-200;
}
.custom-field.radius-8
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__leading {
  border-radius: 8px 0 0 8px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: $blue-600;
}

.custom-field .mat-mdc-form-field-flex {
  height: 48px;
  align-items: center;
}

.custom-field.textarea .mat-mdc-form-field-flex {
  height: fit-content;
  align-items: center;
}

.custom-field.sm .mat-mdc-form-field-flex {
  height: 32px;
  align-items: center;
}

.custom-field.md .mat-mdc-form-field-flex {
  height: 40px;
  align-items: center;
}

.custom-field
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__notch
  label {
  top: 50%;
}

.custom-field
  .mat-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__notch
  label.mdc-floating-label--float-above {
  top: 28px;
}

.custom-field
  .mdc-text-field:not(.mdc-text-field--disabled)
  .mat-mdc-select-value-text {
  @include font(18px, 28px, 400);
  color: $gray-800;
}
.custom-field.sm
  .mdc-text-field:not(.mdc-text-field--disabled)
  .mat-mdc-select-value-text {
  @include font(14px, 20px, 400);
}

.custom-field
  .mdc-text-field:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  color: $gray-800;
  @include font(16px, 20px, 400);
}
.custom-field.sm
  .mdc-text-field:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  @include font(14px, 20px, 400);
}

.custom-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-infix {
  min-height: 50px;
}

.custom-form-field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    @include font(12px, 16px, 600);
    color: $gray-700;
    display: flex;
    align-items: center;
    width: max-content;

    .info-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;
      color: $blue-700;
      margin-left: 5px
    }

    .required{
      color: red;
      margin-left: 4px;
    }

  }

  .mat-mdc-text-field-wrapper{
    background-color: white;
  }

  .mat-mdc-form-field-error {
    @include font(12px, 16px, 600);
    color: $red-500
  }
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
  top: 50%;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  box-shadow: 0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a;
  border: 1px solid $blue-200;
  padding-bottom: 0;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  display: none;
}
