.mat-mdc-outlined-button.sm:not(:disabled) {
  display: flex;
  gap: 8px;

  &.mat-primary {
    border-color: $blue-700;
    .mdc-button__label {
      color: $blue-700;
    }
  }
}

.mat-mdc-unelevated-button.sm > .mat-icon.icon-btn,
.mat-mdc-raised-button.sm > .mat-icon.icon-btn,
.mat-mdc-outlined-button.sm > .mat-icon.icon-btn {
  font-size: 14px;
  height: 14px;
  width: 14px;
  margin: 0;
}

.mat-mdc-raised-button.mat-primary > .mat-icon.icon-btn,
.mat-mdc-outlined-button.mat-primary > .mat-icon.icon-btn {
  color: $blue-700;
}

.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
  border-radius: 8px;
  @include font(16px, 24px, 600);
  padding: 8px 16px;
}

.mat-mdc-button-base.sm:not(:disabled) {
  font-size: 12px;
}

.mat-mdc-button-base.sm > .mat-icon.icon-btn {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 0;
}

.mat-mdc-outlined-button:not(:disabled){
  border: 1px solid $blue-700;
  border-color: $blue-700;
  padding: 10px 16px;
  height: 40px;
  min-width: 40px;
}

.mat-mdc-outlined-button:not(:disabled).primary{
  border: 1px solid $blue-700;
  color: $blue-700;
}

.mat-mdc-outlined-button:not(:disabled).primary.fill{
  background-color: $blue-700;
  color: #FFF;
}

.mat-mdc-outlined-button:not(:disabled).danger{
  border: 1px solid $red-600;
  color: $red-600;
}

.mat-mdc-outlined-button:not(:disabled).success{
  border: 1px solid $green-600;
  color: $green-600;
}

.mat-mdc-outlined-button:not(:disabled).icon-only{
  padding: 10px;
  height: 40px;
  min-width: 40px;

  .mat-icon{
    margin: 0;
  }
}

.mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
}

.mat-mdc-button-base.no-text {
  padding: 10px;

  .mat-icon {
    margin: 0;
  }
}

.mat-mdc-button-base.no-border {
  border: none !important;
}
