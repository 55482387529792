.ag-header-cell,
.ag-header-group-cell {
  height: 36px !important;
  padding: 0;
  background-color: transparent !important;
  .ag-header-cell-label {
    padding: 8px 15px 4px 15px;
    height: 36px !important;

    .ag-header-cell-text {
      color: $blue-800;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.ag-header.ag-header-allow-overflow .ag-header-row {
  height: 36px !important;
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
  height: 36px !important;
}


.ag-header-select-all {
  padding: 14px 15px;
}


.ag-cell-label-container {
  padding: 0;
}

.ag-ltr .ag-header {
  height: 72px !important;
  min-height: 72px !important;
}

.ag-header-row.ag-header-row-column-filter {
  top: 36px !important;

  .ag-icon-filter {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 3.9px;
    height: 16.28px;
    width: 16.28px;
  }

  .ag-icon-filter::before {
    font-size: 8.14px;
    line-height: 8.14px;
    color: $blue-600;
  }

  .ag-floating-filter-button-button {
    border: 0.68px solid $blue-600;
    border-radius: 2.7px;
  }
}

.ag-floating-filter-body input[class^="ag-"][type="text"],
.ag-floating-filter-body input[class^="ag-"][type="date"] {
  height: 16.3px;
  margin: 0;
  border-radius: 1.36px;
  border: 0.68px solid $blue-200;
  @include font(12px, 16px, 400);
  padding: 0;
  background-color: #fff;

  &:focus {
    border: 1px solid $blue-700 !important;
  }
}

.ag-filter-body input[class^="ag-"][type="text"],
.ag-filter-body input[class^="ag-"][type="date"] {
  height: 33px;
  margin: 0;
  border-radius: 8px;
  border: 1px solid $blue-200;
  @include font(14px, 20px, 400);
  padding: 0;
  background-color: #fff;
  padding: 0 12px;

  &:focus {
    border: 1px solid $blue-700 !important;
  }
}

.ag-radio-button-input-wrapper.ag-checked::after {
  color: $blue-700;
}

.ag-header-row:not(:first-child)
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
  padding: 8px 15px;
}

.ag-ltr .ag-floating-filter-button {
  margin-left: 4px;
}

.ag-ltr .ag-cell {
  @include font(12px, 16px, 400);
  color: $gray-600;
  padding: 14px 15px;
  display: flex;
  align-items: center;
}

.ag-header-row:not(:first-child)
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
  border-top: 1px solid $blue-200;
}

.ag-header,
.ag-row {
  border-bottom: 1px solid $blue-200;
}

.ag-paging-page-size {
  margin: 0 15px;

  .ag-label {
    @include font(14px, 20px, 400);
    color: $gray-600;
  }
  .ag-wrapper {
    min-width: max-content !important;
    padding: 6px 12px !important;
    border-radius: 6px;
    border: 1px solid $blue-200;

    .ag-picker-field-display {
      @include font(14px, 20px, 400);
      color: $gray-600;
    }
    .ag-picker-field-icon {
      display: none;
    }
  }
}

.ag-header,
.ag-filter-body-wrapper {
  background-color: $blue-50;
}

.ag-row:not(.ag-row-even) {
  background-color: $blue-50;
}

.ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a;
  border: 1px solid $blue-200;
  border-radius: 8px;
}

.ag-filter-select {
  border-radius: 8px;
  border: 1px solid $blue-200;
  @include font(14px, 20px, 400);
  color: $gray-800;

  .ag-picker-field-wrapper {
    border-radius: 8px;
    background-color: #fff;
  }
}

span.ag-paging-page-summary-panel {
  .ag-paging-description {
    position: absolute;
    right: 72px;
    margin-right: 24px;
    @include font(14px, 20px, 400);
    color: $gray-600;

  }
  .ag-button:nth-child(1),
  .ag-button:nth-child(5) {
    display: none;
  }
}

span.ag-paging-row-summary-panel {
  @include font(14px, 20px, 400);
  color: $gray-600;
  margin: 0;
  display: none;

  span:nth-child(1) {
    color: $gray-800;
  }
  span:nth-child(2),
  span:nth-child(3) {
    display: none !important;
  }
}

span.ag-paging-page-size {
  margin-right: auto;
}

span.ag-paging-page-summary-panel {
  margin-right: 8px;
}

.ag-paging-panel {
  border-top-color: $blue-200;
}


.ag-paging-button.ag-disabled .ag-icon.ag-icon-next, .ag-paging-button.ag-disabled .ag-icon.ag-icon-previous{
  color: rgba(0, 0, 0, 0.2);
}
