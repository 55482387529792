.display-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-flex-centered-x {
  display: flex;
  justify-content: center;
}

.display-flex-centered-y {
  display: flex;
  align-items: center;
}
