.mat-mdc-menu-panel.mat-mdc-menu-panel,
.mdc-menu-surface.mat-mdc-select-panel {
  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
  border: 1px solid $blue-200;
  border-radius: 8px !important;
  margin-top: 8px !important;
}

.mat-mdc-menu-content.mdc-list,
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-mdc-option.mdc-list-item {
  padding: 6px 12px 6px 12px;
  min-height: 40px;

  .mdc-list-item__primary-text {
    @include font(18px, 28px, 400);
    color: $gray-800;
  }
}
.mat-mdc-option.mdc-list-item.small {
  padding: 6px 12px 6px 12px;
  min-height: 32px;

  .mdc-list-item__primary-text {
    @include font(14px, 20px, 400);

    color: $gray-800;
  }
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: $blue-100;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mdc-menu-surface.mat-mdc-select-panel {
  overflow: hidden;
  overflow-y: auto;

  .select-container {
    height: 100%;
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  max-height: 500px !important;
}

.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper {
  min-width: 45px;
}
