.mat-mdc-tab-header .mat-mdc-tab-label-container{
    border-bottom-style: solid;
    border-bottom-color: $gray-200;
    border-bottom-width: 1px;
}

.mdc-tab__text-label{
    font: normal normal 500 18px/28px Inter;
    color: $gray-800;
}

.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label{
    color: $gray-800;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label{
    color: $blue-700;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline{
    border-color: var(--mdc-tab-indicator-active-indicator-color, $blue-700);
}
