.mat-mdc-paginator-container{
    justify-content: space-between !important;

    .mat-mdc-form-field{
        text-align: center;
    }
}

.mat-mdc-paginator-page-size{
    gap: 8px;
}

.mat-mdc-paginator-page-size-label{
    margin: 0px;
    font: normal normal 400 16px/24px $fontMain;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
    border-color: $gray-200;
}

.mat-mdc-paginator-page-size-select {
    width: auto;
    max-width: 32px;
    border-color: $blue-200;
    text-align: center;
    
    .mdc-text-field{
        padding: 0px;
    }
}



.mat-paginator-select-options{

    .mat-mdc-option{
        padding: 0px;

        .mdc-list-item__primary-text{
            font-size: 14px;
            margin: auto;
        }
    }
}