.mdc-form-field>label {
  @include font(14px, 20px, 400);
  color: $gray-800;
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
  display: none;
}

.mat-pseudo-checkbox-full {
  border: 2px solid $blue-200 !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  border: 2px solid $blue-700 !important;

}

.ag-checkbox-input-wrapper::after {
  color: $blue-200;
}
.ag-checkbox-input-wrapper.ag-checked::after {
  color: $blue-700;
}

.mat-mdc-checkbox label {
  letter-spacing: normal;
}
