%scroll_style {
    overflow: auto;
    scrollbar-color: #c1c1c1 #e6e6e6;
    scrollbar-width: none;
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
    &::-webkit-scrollbar-track {
        background: #e6e6e6;
        border-left: 1px solid transparent;
    }
    &::-webkit-scrollbar {
        width: 7px;
    }
}

%scroll_style_h {
    overflow: auto;
    scrollbar-color: #c1c1c1 #e6e6e6;
    scrollbar-width: none;
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
    &::-webkit-scrollbar-track {
        background: #e6e6e6;
        border-left: 1px solid transparent;
    }
    &::-webkit-scrollbar {
        height: 7px;
    }
}

@mixin font($size, $line-height, $font-weight: 400, $font-family: $fontMain, $style: normal, ) {
  font: $style $font-weight #{$size}/#{$line-height} $font-family ;
}
